.portfolio__container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 2.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item-top {
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mySwiper {
  display: flex;
  height: fit-content;
}

.portfolio-item-technologies {
  display: flex;
  flex-direction: column;
  width: 15%;
  padding-left: 2.5rem;
}

.technologies {
  display: block;
  margin: 1rem 1rem 2rem 0;
}

.technologies svg {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.portfolio__item h2 {
  margin: 0.8rem 0 1rem;
}
.portfolio__item-description {
  margin: 1rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.swiper {
  width: 100%;
  border-radius: 1.5rem;
}

/* =========== MEDIA QUERY (MEDIUM DEVICES)=========== */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }
}

/* =========== MEDIA QUERY (SMALL DEVICES)=========== */

@media screen and (max-width: 800px) {
  .swiper {
    display: none !important;
  }
  .portfolio-item-technologies {
    width: 100%;
    padding-left: 0;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
