header {
  height: 70vh;
  padding-top: 4rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ======================== CTA ======================== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ======================== HEADER SOCIALS ======================== */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  bottom: 3rem;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 16rem;
  height: 13rem;
  position: absolute;
  left: calc(50% - 8rem);
  margin-top: 4rem;
  border-radius: 2rem;
  overflow: hidden;
  padding: 5rem, 1.5rem 1.5rem 1.5rem;
  
}

.me img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding-top: 1rem;
}

/* ======================== SCROLL DOWN ======================== */

.scroll__down {
  position: absolute;
  right: -1.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 1.1rem;
}

/* =========== MEDIA QUERY (MEDIUM DEVICES)=========== */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/* =========== MEDIA QUERY (SMALL DEVICES)=========== */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
