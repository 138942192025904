footer {
  background: var(--color-primary);
  padding: 1rem 0;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 6rem;
  /* padding-bottom: 7rem; */
}

footer a {
  color: var(--color-bg);
}

.footer__logo {

  margin-bottom: 0.5rem;
  display: inline-block;
  /* height: 6rem;
  width: 6rem; */
}
.footer__logo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4rem;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  font-size: large;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 1.5rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 8rem;
  font-size: large;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.9rem;
  border-radius: 0.8rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}



/* =========== MEDIA QUERY (SMALL DEVICES)=========== */

@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1rem;
  }

  .footer__socials {
    margin-bottom: 8rem;
  }
}
