.externalSingleProjectContainer {
  font-family: 'Muli', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  overflow: hidden;
  margin: 0;
}

.spContainer {
  display: flex;
}

.spContainer > .panel:not(.active) {
  background-color: var(--color-primary-variant);
}

.spContainer > .panel:not(.active):hover {
  background: var(--color-primary-variant-light);
}


.panel {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
  width: 100%;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  flex: 0.2;
  margin: 10px;
  position: relative;
  /* transition: var(--transition); */
}

.project-image {
  height: 100%;
  width: 100%;
  border-radius: 30px;
  object-fit: cover; 
}

.panel h3 {
  font-size: 24px;
  position: absolute;
  bottom: 20px;
  left: 20px;
  opacity: 0;
}

.panel.active {
  flex: 5;
}

.panel.active h3 {
  opacity: 1;
  /* transition: var(--transition); */
}

@media (max-width: 800px) {
  .container {
    width: 100vw;
  }
  .panel:nth-of-type(4),
  .panel:nth-of-type(5) {
    display: none;
  }
}

